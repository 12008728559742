import { Modal } from 'lib-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import DepositDocument from './deposit-document';
import useDepositMediaCheckDownload from 'core/api/client-portal/hooks/deposits/use-deposit-media-check-download';
import useDepositVerificationDownload, {
    DepositVerificationDownloadParams
} from 'core/api/client-portal/hooks/deposits/use-deposit-verification-download';
import useDepositVerificationList from 'core/api/client-portal/hooks/deposits/use-deposit-verification-list';
import useIntegrityReport from 'core/api/client-portal/hooks/document/use-integrity-report';
import useIntegrityReportDownload, {
    IntegrityReportDownloadParams
} from 'core/api/client-portal/hooks/document/use-integrity-report-download';

interface DepositDocumentsModalProps {
    agreementId: number;
    agreementNumber: number;
    depositId: number;
    mediaCheck: boolean | undefined;
}

function DepositDocumentsModal({
    agreementId,
    agreementNumber,
    depositId,
    mediaCheck
}: DepositDocumentsModalProps) {
    const { t } = useTranslation();

    const {
        data: integrityReports,
        isFetching: isFetchingIntegrityReport,
        isLoading: isLoadingIntegrityReport
    } = useIntegrityReport({
        agreementId,
        depositId
    });

    const [shouldFetchVerification, setShouldFetchVerification] =
        useState(false);

    useEffect(() => {
        if (!isFetchingIntegrityReport && !isLoadingIntegrityReport) {
            setShouldFetchVerification(true);
        }
    }, [isFetchingIntegrityReport, isLoadingIntegrityReport]);

    const {
        data: verificationReports,
        isFetching: isFetchingVerificationReports,
        isLoading: isLoadingVerificationReports
    } = useDepositVerificationList({
        agreementId: agreementId,
        depositId: depositId,
        enabled: shouldFetchVerification // This ensures the hook runs only when shouldFetchVerification is true
    });

    const getAllData = async () => {
        const vReports = await verificationReports;
        return vReports;
    };

    getAllData();

    const {
        isLoading: isLoadingIntegrityDownload,
        mutate: requestIntegrityDownload
    } = useIntegrityReportDownload();

    const {
        isLoading: isLoadingDepositMediaCheckDownload,
        mutate: requestDepositMediaCheckDownload
    } = useDepositMediaCheckDownload();

    const {
        isLoading: isLoadingVerificationDownload,
        mutate: requestVerificationDownload
    } = useDepositVerificationDownload();

    const downloadMediaCheck = useCallback(() => {
        requestDepositMediaCheckDownload({
            agreementNumber,
            depositId
        });
    }, [agreementNumber, depositId, requestDepositMediaCheckDownload]);

    const reportsLoading =
        isLoadingVerificationReports ||
        isFetchingVerificationReports ||
        isLoadingIntegrityReport ||
        isFetchingIntegrityReport;

    const downloadVerification = useCallback(
        (data?: DepositVerificationDownloadParams) => {
            if (!data) return;
            requestVerificationDownload(data);
        },
        [requestVerificationDownload]
    );

    const downloadIntegrity = useCallback(
        (data?: IntegrityReportDownloadParams) => {
            if (!data) return;
            requestIntegrityDownload(data);
        },
        [requestIntegrityDownload]
    );

    return (
        <Modal>
            <Modal.Header>{'Verification Reports'}</Modal.Header>

            {reportsLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
                <Modal.Body className="flex flex-col items-center gap-2.5">
                    {(verificationReports?.items?.length ?? 0) === 0 && (
                        <>{t('deposit-documents.no-data')}</>
                    )}

                    {!reportsLoading &&
                        verificationReports?.items
                            ?.filter(
                                ({ index, slxType }) => !!index && !!slxType
                            )
                            .map(({ index, slxType }) => (
                                <>
                                    <DepositDocument<DepositVerificationDownloadParams>
                                        key={index}
                                        name={slxType!}
                                        onDownload={downloadVerification}
                                        data={{
                                            agreementId,
                                            depositId,
                                            index: index!,
                                            slxType: slxType!
                                        }}
                                        loading={isLoadingVerificationDownload}
                                    />
                                </>
                            ))}

                    <span className="w-full relative text-xl font-medium text-general-grey-grey-90 text-left border-b border-escode-grey-30 pb-2">
                        {t('deposit-documents.title')}
                    </span>
                    {!mediaCheck &&
                        (integrityReports?.items?.length ?? 0) === 0 && (
                            <>{t('deposit-documents.no-data')}</>
                        )}
                    {!!mediaCheck && (
                        <DepositDocument
                            name={t('deposits.header.media-check')}
                            onDownload={downloadMediaCheck}
                            loading={isLoadingDepositMediaCheckDownload}
                        />
                    )}

                    {!reportsLoading &&
                        integrityReports?.items
                            ?.filter(
                                ({ index, slxType }) => !!index && !!slxType
                            )
                            .map(({ index, slxType }) => (
                                <DepositDocument<DepositVerificationDownloadParams>
                                    key={index}
                                    name={slxType!}
                                    onDownload={downloadIntegrity}
                                    data={{
                                        agreementId,
                                        depositId,
                                        index: index!,
                                        slxType: slxType!
                                    }}
                                    loading={isLoadingIntegrityDownload}
                                />
                            ))}
                </Modal.Body>
            )}
        </Modal>
    );
}

export default DepositDocumentsModal;
export type { DepositDocumentsModalProps };
