import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';

import cn from 'core/utils/cn';
import toast from 'react-hot-toast';

function customToast({
    message,
    type
}: {
    message: string;
    type: 'success' | 'error';
}) {
    return toast.custom((t) => (
        <Transition
            appear
            className={cn(
                'flex items-center transform gap-3 p-3 rounded-lg shadow-lg bg-brand-escode-tourmalineblack-tourmalineblack-130 text-sm text-general-grey-grey-30'
            )}
            data-testid="toast"
            enter="transition-all duration-150"
            enterFrom="opacity-0 scale-50"
            enterTo="opacity-100 scale-100"
            leave="transition-all duration-150"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-75"
            show={t.visible}
        >
            {type === 'success' && (
                <FontAwesomeIcon
                    icon={type === 'success' ? faCheckCircle : faTimesCircle}
                    className="text-white"
                />
            )}

            <p className="text-general-grey-grey-30"> {message}</p>
            <button onClick={() => toast.dismiss(t.id)} type="button">
                <FontAwesomeIcon icon={faTimesCircle} className="text-white" />
            </button>
        </Transition>
    ));
}

const toastFunctions = {
    error: (message: string) => customToast({ message, type: 'error' }),
    success: (message: string) => customToast({ message, type: 'success' })
};

export default toastFunctions;
