import { PropsWithChildren, ReactNode, useCallback } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import { FeedbackFormTriggers } from 'ui/feedback-form/feedback-form-modal';
import { NavLink } from 'react-router-dom';
import { useAuthActions } from 'common/use-auth-actions';
import Button from '../../button/button';
import Modal from './modal';
import ModalBody from './modal-body';
import ModalHeader from './modal-header';
import classNames from 'classnames';

interface ResultModalProps {
    description?: ReactNode;
    navigate?: string | undefined;
    title?: ReactNode;
    trigger?: FeedbackFormTriggers;
}

function ResultModal({
    children,
    description,
    navigate,
    title,
    trigger
}: PropsWithChildren<ResultModalProps>) {
    const { pop } = useModals();
    const { t } = useTranslation();
    const { handleSignoutRedirect } = useAuthActions();

    const handleCloseModal = useCallback(() => {
        pop();
        if (trigger === 'userPanelLogOut') {
            localStorage.removeItem('feedback-form-submitted');
            handleSignoutRedirect();
        }
    }, [handleSignoutRedirect, pop, trigger]);

    const cssClasses = classNames('flex', 'mt-2', 'gap-2', {
        'justify-center': !navigate,
        'justify-start': navigate
    });

    return (
        <Modal>
            <ModalHeader suppressClose={trigger === 'userPanelLogOut'}>
                <div className="font-medium text-brand-escode-neonblue-neonblue-100">
                    {title}
                </div>
            </ModalHeader>
            <ModalBody className="flex flex-col text-center gap-2">
                <div>{description}</div>
                {children}
                <div className={cssClasses}>
                    {navigate && (
                        <NavLink to={navigate} onClick={handleCloseModal}>
                            <Button variant="tertiary">
                                {t(
                                    'add-source-control-connection.success-to-schedules'
                                )}
                            </Button>
                        </NavLink>
                    )}
                    <Button onClick={handleCloseModal} variant="danger">
                        {t('modal.close')}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ResultModal;
export type { ResultModalProps };
