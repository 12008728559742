import { Button } from 'lib-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface DepositDocumentProps<TData = unknown> {
    data?: TData;
    loading?: boolean;
    name: string;
    onDownload: (data?: TData) => void;
}

function DepositDocument<TData>({
    data,
    loading,
    name,
    onDownload
}: DepositDocumentProps<TData>) {
    const { t } = useTranslation();

    const download = useCallback(() => {
        onDownload(data);
    }, [data, onDownload]);

    return (
        <div className="flex items-center justify-between gap-2 min-w-xs w-full">
            {name}
            <Button variant="tertiary" onClick={download} loading={loading}>
                {t('download')}
            </Button>
        </div>
    );
}

export default DepositDocument;
export type { DepositDocumentProps };
