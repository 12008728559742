import {
    AgreementListResponseViewModel,
    AgreementViewModel
} from 'core/api/client-portal/autogenerated/data-contracts';
import { Modal } from 'lib-ui';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { getAgreementDepositStatus } from 'core/api/client-portal/model/agreement-deposit-status';
import HighlightAgreementGridItem from './highlight-agreement-grid-item';
import useAgreementsList from 'core/api/client-portal/hooks/agreements/use-agreements-list';

interface HighlightAgreementsModalProps {
    data: AgreementViewModel[];
}

function HighlightAgreementsModal({ data }: HighlightAgreementsModalProps) {
    const { t } = useTranslation();

    function getHighLightAgreements(
        data: AgreementListResponseViewModel | undefined
    ) {
        return data?.agreements?.filter(
            (item) =>
                !(['verified', 'standard'] as (string | undefined)[]).includes(
                    getAgreementDepositStatus(item)
                )
        );
    }

    const {
        data: agreements,
        isFetching,
        isLoading
    } = useAgreementsList(
        {
            IncludeRestrictedAgreements: true
        },
        {
            onSettled: (data) => {
                getHighLightAgreements(data);
            }
        }
    );
    const loading = isFetching || isLoading;

    const highlightAgreements = getHighLightAgreements(agreements);

    return (
        <Modal size="m">
            <Modal.Header>
                {t('dashboard.highlight-agreements-widget.title')}
            </Modal.Header>
            <Modal.Body className="flex flex-col gap-2.5">
                {loading && (
                    <div className="flex justify-center items-center">
                        <FontAwesomeIcon
                            className="h-4 w-4 mx-auto"
                            icon={faSpinner}
                            spin
                        />
                    </div>
                )}
                {!loading &&
                    highlightAgreements?.map((item, index) => (
                        <HighlightAgreementGridItem
                            key={item.agreementId}
                            agreementNumber={item.agreementNumber}
                            agreementId={item.agreementId}
                            viewingPartyCanCreateDeposit={
                                item.viewingPartyCanCreateDeposit
                            }
                        />
                    ))}
            </Modal.Body>
        </Modal>
    );
}

export default HighlightAgreementsModal;
export type { HighlightAgreementsModalProps };
