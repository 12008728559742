import { MutationOptions, useMutation } from 'react-query';

import { AxiosResponse } from 'axios';
import { useAuth } from 'react-oidc-context';

import { ApiController } from '../../autogenerated/Api';

import { RequestParams } from '../../autogenerated/http-client';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

type RegistrationDetails = {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    agreementNumber: undefined;
    cloudFlareToken: string | undefined;
    company: string;
    emailAddress: string;
    firstName: string;
    jobTitle: string;
    lastName: string;
    password: string;
    postcode: string;
    telephone: string;
    title: string;
};

type CreateRegistrationType = {
    data: RegistrationDetails;
    params?: RequestParams;
};

function useRegisterUser<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void, unknown>,
            ReactQueryError<TError>,
            CreateRegistrationType,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation<
        AxiosResponse,
        ReactQueryError<TError>,
        CreateRegistrationType,
        TContext
    >(
        ['user-registration'],
        async ({ data, params }) => {
            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            return controller.v2RegisterCreate(data, params);
        },
        {
            ...options
        }
    );
}

export default useRegisterUser;
