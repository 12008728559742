import { Button, ErrorPanel } from 'lib-ui';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Suspense, useCallback } from 'react';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { useLocalStorage } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import AgreementDetailsBody from './bodies/agreement-details-body';
import AgreementsBody from './bodies/agreements-body';
import ComingSoonBody from './bodies/coming-soon-body';
import ContactsBody from './bodies/contacts-body';
import DashboardBody from './bodies/dashboard-body';
import MainHeader from './headers/main-header';
import MainSider from './siders/main-sider';
import SchedulesBody from './bodies/schedules-body';
import SourceControlConnectionsBody from './bodies/source-control-connections-body';

import './app-layout.scss';
import { useAuth } from 'react-oidc-context';
import AuthLayout from './auth-layout';
import ConfirmRegistration from 'ui/auth/confirm-registration';
import DepositNowAgreementSelect from 'app-layout/bodies/deposit-now-agreement-select';
import DepositNowContactDetails from 'app-layout/bodies/deposit-now-contact-details';
import DepositNowContent from 'app-layout/bodies/deposit-now-content';
import DepositNowDepositDetails from 'app-layout/bodies/deposit-now-deposit-details';
import DepositNowDisclaimer from './bodies/deposit-now-disclaimer';
import DepositNowReview from 'app-layout/bodies/deposit-now-review';
import DepositNowUpload from './bodies/deposit-now-upload';
import ExternalTermsAndConditions from 'ui/terms-and-conditions/external-terms-and-conditions';
import HelpBody from './bodies/help-body';
import Login from 'ui/auth';
import MaintenanceBody from './bodies/maintenance-body';
import Register from 'ui/auth/register';
import SettingsAuditBody from './bodies/settings-audit-body';
import SettingsNotificationsBody from './bodies/settings-notifications-body';
import SettingsProfileBody from './bodies/settings-profile-body';
import SettingsSystemBody from './bodies/settings-system-body';
import SettingsTerminationBody from './bodies/settings-termination-body';
import SupportBody from './bodies/support-body';
import TermsAndConditionsBody from './bodies/terms-and-conditions-body';
import WorkingOnBody from './bodies/working-on-body';

const MAIN_HEADER_PATHS = [
    '/',
    '/agreements',
    '/agreements/:id',
    '/source-control',
    '/schedules',
    '/contacts',
    '/help',
    '/support',
    '/terms-and-conditions',
    '/settings'
];
const MAIN_SIDER_PATHS = [
    '/',
    '/agreements',
    '/agreements/:id',
    '/source-control',
    '/schedules',
    '/contacts',
    '/help',
    '/support',
    '/terms-and-conditions',
    '/settings'
];

function AppLayout() {
    const { isAuthenticated } = useAuth();
    const { t } = useTranslation();
    const location = useLocation();
    const goTo = useNavigate();

    const [collapsed, setCollapsed] = useLocalStorage<boolean>(
        'sider-collapsed',
        true
    );

    const bodyCssClasses = classNames(
        'relative flex-1 mb-escode-body-bottom mt-escode-body-top transition-all',
        {
            'ml-sider': !collapsed,
            'ml-sider-collapsed': collapsed
        }
    );

    const onToggleClick = useCallback(() => {
        setCollapsed(!collapsed);
    }, [collapsed, setCollapsed]);

    return (
        // IMPORTANT: Each section has it's own <Routes /> component as to improve suspense loading experience.
        // If we where to wrap everything into one <Routes /> and have each page define it's own sections those
        // common components will also be "removed" during lazy loading, even though they will not have change
        // even if the body section changes. Because of this, and at the cost of having repetition on the routes,
        // it is has been implemented in this way.

        <>
            <div className="w-screen h-screen relative flex">
                {/* Bodies */}

                <Suspense fallback={'Loading'}>
                    <TransitionGroup
                        className={!isAuthenticated ? '' : bodyCssClasses}
                        data-testid="body-wrapper"
                    >
                        <CSSTransition
                            key={location.key}
                            classNames="app-layout__body"
                            timeout={300}
                        >
                            <>
                                <Routes location={location}>
                                    {!isAuthenticated && (
                                        <>
                                            <Route element={<AuthLayout />}>
                                                <Route
                                                    element={
                                                        <ExternalTermsAndConditions />
                                                    }
                                                    path="/terms"
                                                />
                                            </Route>
                                            <Route element={<AuthLayout />}>
                                                <Route
                                                    element={<Login />}
                                                    path="/login"
                                                />
                                                <Route
                                                    element={<Register />}
                                                    path="/register"
                                                />
                                                <Route
                                                    element={
                                                        <ConfirmRegistration />
                                                    }
                                                    path="/confirm-registration"
                                                />
                                            </Route>
                                            <Route element={<AuthLayout />}>
                                                <Route
                                                    element={<Login />}
                                                    path="*"
                                                />
                                            </Route>
                                        </>
                                    )}
                                    {isAuthenticated && (
                                        <>
                                            <Route
                                                path="/"
                                                element={<DashboardBody />}
                                            />
                                            <Route
                                                path="/agreements"
                                                element={<AgreementsBody />}
                                            />

                                            <Route
                                                path="/agreements/:id/:agreementNumber"
                                                element={
                                                    <AgreementDetailsBody />
                                                }
                                            />
                                            <Route
                                                path="/source-control"
                                                element={
                                                    <SourceControlConnectionsBody />
                                                }
                                            />
                                            <Route
                                                path="/schedules"
                                                element={<SchedulesBody />}
                                            />
                                            <Route
                                                path="/contacts"
                                                element={<ContactsBody />}
                                            />
                                            <Route
                                                path="/help"
                                                element={<HelpBody />}
                                            />
                                            <Route
                                                path="/support"
                                                element={<SupportBody />}
                                            />
                                            <Route
                                                path="/terms-and-conditions"
                                                element={
                                                    <TermsAndConditionsBody />
                                                }
                                            />
                                            <Route
                                                path="/settings/profile"
                                                element={
                                                    <SettingsProfileBody />
                                                }
                                            />
                                            <Route
                                                path="/settings/system"
                                                element={<SettingsSystemBody />}
                                            />
                                            <Route
                                                path="/settings/notifications"
                                                element={
                                                    <SettingsNotificationsBody />
                                                }
                                            />
                                            <Route
                                                path="/settings/audit-history"
                                                element={<SettingsAuditBody />}
                                            />
                                            <Route
                                                path="/settings/request-termination"
                                                element={
                                                    <SettingsTerminationBody />
                                                }
                                            />
                                            <Route
                                                path="/profile"
                                                element={<ComingSoonBody />}
                                            />
                                            <Route
                                                path="/deposit-now"
                                                element={
                                                    <DepositNowAgreementSelect />
                                                }
                                            />
                                            <Route
                                                path="/deposit-now/:depositId/contact-details"
                                                element={
                                                    <DepositNowContactDetails />
                                                }
                                            />
                                            <Route
                                                path="/deposit-now/:depositId/deposit-details"
                                                element={
                                                    <DepositNowDepositDetails />
                                                }
                                            />
                                            <Route
                                                path="/deposit-now/:depositId/content"
                                                element={<DepositNowContent />}
                                            />
                                            <Route
                                                path="/deposit-now/:depositId/review-deposit"
                                                element={<DepositNowReview />}
                                            />
                                            <Route
                                                path="/deposit-now/:depositId/disclaimer"
                                                element={
                                                    <DepositNowDisclaimer />
                                                }
                                            />
                                            <Route
                                                path="/deposit-now/:depositId/upload-files"
                                                element={<DepositNowUpload />}
                                            />

                                            <Route
                                                path="/maintenance"
                                                element={<MaintenanceBody />}
                                            />

                                            <Route
                                                path="/working-on"
                                                element={<WorkingOnBody />}
                                            />
                                            <Route
                                                path="*"
                                                element={
                                                    <div className="h-full w-full px-escode-body-padding-x py-escode-body-padding-y">
                                                        <ErrorPanel code={404}>
                                                            <Button
                                                                icon={
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faArrowLeft
                                                                        }
                                                                    />
                                                                }
                                                                onClick={() =>
                                                                    goTo('/')
                                                                }
                                                            >
                                                                {t(
                                                                    'links.home'
                                                                )}
                                                            </Button>
                                                        </ErrorPanel>
                                                    </div>
                                                }
                                            />
                                        </>
                                    )}
                                </Routes>
                            </>
                        </CSSTransition>
                    </TransitionGroup>
                </Suspense>

                {isAuthenticated && (
                    <>
                        <Routes>
                            {MAIN_HEADER_PATHS.map((item) => (
                                <Route
                                    key={item}
                                    path={item}
                                    element={
                                        <MainHeader collapsed={collapsed} />
                                    }
                                />
                            ))}
                            <Route
                                path="*"
                                element={<MainHeader collapsed={collapsed} />}
                            />
                        </Routes>

                        <Routes>
                            {MAIN_SIDER_PATHS.map((item) => (
                                <Route
                                    key={item}
                                    path={item}
                                    element={
                                        <MainSider
                                            onToggleClick={onToggleClick}
                                            collapsed={collapsed}
                                        />
                                    }
                                />
                            ))}
                            <Route
                                path="*"
                                element={
                                    <MainSider
                                        onToggleClick={onToggleClick}
                                        collapsed={collapsed}
                                    />
                                }
                            />
                        </Routes>
                    </>
                )}
            </div>
        </>
    );
}

export default AppLayout;
