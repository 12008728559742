import { Button, Modal, ResultModal, Stepper, StepperProps } from 'lib-ui';
import { useCallback, useMemo, useState } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import { useAuthActions } from 'common/use-auth-actions';
import ComparisonStep, { ComparisonStepProps } from './steps/comparison-step';
import EaseOfUseStep, { EaseOfUseStepProps } from './steps/ease-of-use-step';
import ExperienceStep, { ExperienceStepProps } from './steps/experience-step';
import ImprovementsStep, {
    ImprovementsStepProps
} from './steps/improvements-step';
import NewFeaturesStep, {
    NewFeaturesStepProps
} from './steps/new-features-step';
import useSubmitFeedback from 'core/api/client-portal-v2/hooks/feedback/use-submit-feedback';

type FeedbackFormTriggers =
    | 'betaBanner'
    | 'userPanelLogOut'
    | 'userPanelFeedback';

type FeedbackFormModalProps = {
    trigger?: FeedbackFormTriggers;
};

type Step =
    | 'ease-of-use'
    | 'new-features'
    | 'experience'
    | 'improvements'
    | 'comparison';

type FeedbackFormValues = {
    experience: string[];
    experienceComment: string;
    improvements: string[];
    improvementsComment: string;
    isEasierToUse: 'yes' | 'no' | '';
    isEasierToUseComment: string;
    isImproved: 'yes' | 'no' | '';
    isImprovedComment: string;
    newFeatureAgreementWidgets: string;
    newFeatureDatePicker: string;
    newFeatureDepositScheduler: string;
    newFeatureDynamicDashboard: string;
    // newFeatureFileManager: string;
    // newFeatureQuickActions: string;
    newFeatureSmartFilters: string;
    newFeaturesComment: string;
};

function FeedbackFormModal({ trigger }: FeedbackFormModalProps) {
    const { t } = useTranslation();
    const { pop, push } = useModals();
    const { handleSignoutRedirect } = useAuthActions();

    const [step, setStep] = useState<Step>('ease-of-use');
    const [data, setData] = useState<FeedbackFormValues>({
        experience: [],
        experienceComment: '',
        improvements: [],
        improvementsComment: '',
        isEasierToUse: '',
        isEasierToUseComment: '',
        isImproved: '',
        isImprovedComment: '',
        newFeatureAgreementWidgets: '',
        newFeatureDatePicker: '',
        newFeatureDepositScheduler: '',
        newFeatureDynamicDashboard: '',
        // newFeatureFileManager: '',
        // newFeatureQuickActions: '',
        newFeatureSmartFilters: '',
        newFeaturesComment: ''
    });

    const handleLogout = () => {
        pop();
        localStorage.removeItem('beta-banner');
        handleSignoutRedirect();
    };

    const { isLoading, mutate: submit } = useSubmitFeedback({
        onError: () => {
            push(ResultModal, {
                description: t('result.error-description'),
                title: t('feedback-form.error-title')
            });
        },
        onSuccess: () => {
            localStorage.setItem('feedback-form-submitted', 'true');
            pop();
            push(ResultModal, {
                description: t('feedback-form.success-description'),
                title: t('feedback-form.success-title'),
                trigger
            });
        }
    });

    const steps = useMemo<StepperProps<Step>['steps']>(
        () => [
            {
                node: t('feedback-form.step-ease-of-use'),
                step: 'ease-of-use'
            },
            {
                node: t('feedback-form.step-new-features'),
                step: 'new-features'
            },
            {
                node: t('feedback-form.step-experience'),
                step: 'experience'
            },
            {
                node: t('feedback-form.step-improvements'),
                step: 'improvements'
            },
            {
                node: t('feedback-form.step-comparison'),
                step: 'comparison'
            }
        ],
        [t]
    );

    const handleStepChange = useCallback((value: Step) => {
        setStep(value);
    }, []);

    const next = () => {
        if (step === 'comparison') {
            submit({
                data: {
                    experience: data.experience,
                    experienceComment: data.experienceComment,
                    improvements: data.improvements,
                    improvementsComment: data.improvementsComment,
                    isEasierToUse: data.isEasierToUse === 'yes',
                    isEasierToUseComment: data.isEasierToUseComment,
                    isImproved: data.isImproved === 'yes',
                    isImprovedComment: data.isImprovedComment,
                    newFeatureAgreementWidgets: parseInt(
                        data.newFeatureAgreementWidgets
                    ),
                    newFeatureDatePicker: parseInt(data.newFeatureDatePicker),
                    newFeatureDepositScheduler: parseInt(
                        data.newFeatureDepositScheduler
                    ),
                    newFeatureDynamicDashboard: parseInt(
                        data.newFeatureDynamicDashboard
                    ),
                    // newFeatureFileManager: parseInt(data.newFeatureFileManager),
                    // newFeatureQuickActions: parseInt(
                    //     data.newFeatureQuickActions
                    // ),
                    newFeatureSmartFilters: parseInt(
                        data.newFeatureSmartFilters
                    ),
                    newFeaturesComment: data.newFeaturesComment
                }
            });
        } else if (step === 'ease-of-use') {
            setStep('new-features');
        } else if (step === 'new-features') {
            setStep('experience');
        } else if (step === 'experience') {
            setStep('improvements');
        } else {
            setStep('comparison');
        }
    };

    const back = () => {
        if (step === 'comparison') {
            setStep('improvements');
        } else if (step === 'improvements') {
            setStep('experience');
        } else if (step === 'experience') {
            setStep('new-features');
        } else if (step === 'new-features') {
            setStep('ease-of-use');
        }
    };

    const handleEaseOfUseChange = useCallback<EaseOfUseStepProps['onChange']>(
        (value) => {
            setData((prev) => ({
                ...prev,
                isEasierToUse: value.isEasierToUse ?? '',
                isEasierToUseComment: value.isEasierToUseComment ?? ''
            }));
        },
        []
    );

    const handleNewFeaturesChange = useCallback<
        NewFeaturesStepProps['onChange']
    >((value) => {
        setData((prev) => ({
            ...prev,
            newFeatureAgreementWidgets: value.newFeatureAgreementWidgets ?? '',
            newFeatureDatePicker: value.newFeatureDatePicker ?? '',
            newFeatureDepositScheduler: value.newFeatureDepositScheduler ?? '',
            newFeatureDynamicDashboard: value.newFeatureDynamicDashboard ?? '',
            // newFeatureFileManager: value.newFeatureFileManager ?? '',
            // newFeatureQuickActions: value.newFeatureQuickActions ?? '',
            newFeatureSmartFilters: value.newFeatureSmartFilters ?? '',
            newFeaturesComment: value.newFeaturesComment ?? ''
        }));
    }, []);

    const handleExperienceChange = useCallback<ExperienceStepProps['onChange']>(
        (value) => {
            setData((prev) => ({
                ...prev,
                experience:
                    (value.experience?.filter(Boolean) as
                        | string[]
                        | undefined) ?? [],
                experienceComment: value.experienceComment ?? ''
            }));
        },
        []
    );

    const handleImprovementsChange = useCallback<
        ImprovementsStepProps['onChange']
    >((value) => {
        setData((prev) => ({
            ...prev,
            improvements:
                (value.improvements?.filter(Boolean) as string[] | undefined) ??
                [],
            improvementsComment: value.improvementsComment ?? ''
        }));
    }, []);

    const handleComparisonChange = useCallback<ComparisonStepProps['onChange']>(
        (value) => {
            setData((prev) => ({
                ...prev,
                isImproved: value.isImproved ?? '',
                isImprovedComment: value.isImprovedComment ?? ''
            }));
        },
        []
    );

    return (
        <Modal>
            <Modal.Header>{t('feedback-form.title')}</Modal.Header>
            <Modal.Body>
                <div className="flex flex-col gap-6">
                    <Stepper
                        steps={steps}
                        active={step}
                        onChange={handleStepChange}
                        extra={
                            <>
                                <Button
                                    onClick={back}
                                    variant="tertiary"
                                    disabled={step === 'ease-of-use'}
                                >
                                    {t('feedback-form.previous-step')}
                                </Button>
                                <Button
                                    onClick={next}
                                    loading={isLoading}
                                    variant="secondary"
                                >
                                    {step !== 'comparison'
                                        ? t('feedback-form.next-step')
                                        : t('feedback-form.submit')}
                                </Button>
                            </>
                        }
                    />
                    {step === 'ease-of-use' && (
                        <EaseOfUseStep
                            onChange={handleEaseOfUseChange}
                            defaultValues={{
                                isEasierToUse: data.isEasierToUse,
                                isEasierToUseComment: data.isEasierToUseComment
                            }}
                        />
                    )}
                    {step === 'new-features' && (
                        <NewFeaturesStep
                            onChange={handleNewFeaturesChange}
                            defaultValues={{
                                newFeatureAgreementWidgets:
                                    data.newFeatureAgreementWidgets,
                                newFeatureDatePicker: data.newFeatureDatePicker,
                                newFeatureDepositScheduler:
                                    data.newFeatureDepositScheduler,
                                newFeatureDynamicDashboard:
                                    data.newFeatureDynamicDashboard,
                                // newFeatureFileManager:
                                //     data.newFeatureFileManager,
                                // newFeatureQuickActions:
                                //     data.newFeatureQuickActions,
                                newFeatureSmartFilters:
                                    data.newFeatureSmartFilters,
                                newFeaturesComment: data.newFeaturesComment
                            }}
                        />
                    )}
                    {step === 'experience' && (
                        <ExperienceStep
                            onChange={handleExperienceChange}
                            defaultValues={{
                                experience: data.experience,
                                experienceComment: data.experienceComment
                            }}
                        />
                    )}
                    {step === 'improvements' && (
                        <ImprovementsStep
                            onChange={handleImprovementsChange}
                            defaultValues={{
                                improvements: data.improvements,
                                improvementsComment: data.improvementsComment
                            }}
                        />
                    )}
                    {step === 'comparison' && (
                        <ComparisonStep
                            onChange={handleComparisonChange}
                            defaultValues={{
                                isImproved: data.isImproved,
                                isImprovedComment: data.isImprovedComment
                            }}
                        />
                    )}
                </div>
            </Modal.Body>
            {trigger === 'userPanelLogOut' && (
                <Modal.Footer suppressCloseButton>
                    <Button onClick={handleLogout} variant="danger">
                        {t('feedback-form.logout')}
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    );
}

export default FeedbackFormModal;
export type { FeedbackFormTriggers };
