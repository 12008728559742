import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowDownToLine,
    faArrowUpRightFromSquare,
    faEnvelope
} from '@fortawesome/pro-regular-svg-icons';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

import '../../lib-ui/error/error-panel.scss';
import { Button, CollapsiblePanel } from 'lib-ui';
// import SearchBar from 'lib-ui/search-bar/search-bar';
import ContactUsModal from './contact-us-modal';
import SectionHeading from 'lib-ui/form/element/section-heading';

const Help = () => {
    const { t } = useTranslation();
    const { push } = useModals();
    const currentYear = new Date().getFullYear();

    const generalQuestions = [
        {
            detail: t('faq.general-questions.1.detail'),
            summary: t('faq.general-questions.1.summary')
        },
        {
            detail: t('faq.general-questions.2.detail'),
            summary: t('faq.general-questions.2.summary')
        },
        {
            detail: t('faq.general-questions.3.detail'),
            summary: t('faq.general-questions.3.summary')
        },
        {
            detail: t('faq.general-questions.4.detail'),
            summary: (
                <div className="flex flex-col gap-4">
                    <p>{t('faq.general-questions.4.summary.1')}</p>
                    <p>{t('faq.general-questions.4.summary.2')}</p>
                </div>
            )
        },
        {
            detail: t('faq.general-questions.5.detail'),

            summary: (
                <div className="flex flex-col gap-4">
                    <p>{t('faq.general-questions.5.summary.1')}</p>
                    <p>{t('faq.general-questions.5.summary.2')}</p>
                </div>
            )
        }
    ];

    const depositQuestions = [
        {
            detail: t('faq.deposit-questions.1.detail'),
            summary: (
                <div className="flex flex-col gap-4">
                    <p>{t('faq.deposit-questions.1.summary.1')}</p>

                    <p>{t('faq.deposit-questions.1.summary.2')}</p>
                </div>
            )
        },
        {
            detail: t('faq.deposit-questions.2.detail'),
            summary: (
                <div className="flex flex-col gap-4">
                    <p>{t('faq.deposit-questions.2.summary.1')}</p>
                    <p>{t('faq.deposit-questions.2.summary.2')}</p>
                </div>
            )
        },
        {
            detail: t('faq.deposit-questions.3.detail'),
            summary: (
                <div className="flex flex-col gap-4">
                    <p>{t('faq.deposit-questions.3.summary.1')}</p>
                    <p>{t('faq.deposit-questions.3.summary.2')}</p>
                    <p>
                        {t('faq.deposit-questions.3.summary.3')}{' '}
                        <a
                            className="text-brand-escode-neonblue-neonblue-120 underline"
                            href="/assets/downloads/view_portal_vendor_handbook.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            View {t('faq.view-handbook')}
                        </a>
                        .
                    </p>
                </div>
            )
        },
        {
            detail: t('faq.deposit-questions.4.detail'),
            summary: t('faq.deposit-questions.4.summary')
        },
        {
            detail: t('faq.deposit-questions.5.detail'),
            summary: (
                <div className="flex flex-col gap-4">
                    <p>{t('faq.deposit-questions.5.summary.1')}</p>
                    <p>{t('faq.deposit-questions.5.summary.2')}</p>
                    <p>
                        {t('faq.deposit-questions.5.summary.3')}{' '}
                        <a
                            className="text-brand-escode-neonblue-neonblue-120 underline"
                            href="/assets/downloads/view_portal_vendor_handbook.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            View {t('faq.view-handbook')}
                        </a>
                        .
                    </p>
                </div>
            )
        },
        {
            detail: t('faq.deposit-questions.6.detail'),
            summary: (
                <div className="flex flex-col gap-4">
                    <p>{t('faq.deposit-questions.6.summary.1')}</p>
                    <p>{t('faq.deposit-questions.6.summary.2')}</p>
                </div>
            )
        }
    ];

    const handleContactModal = () => {
        push(ContactUsModal, {});
    };

    return (
        <div className="flex flex-col gap-16">
            <div className="w-full h-[252px]">
                <div className="h-full w-full bg-gradient-to-r from-escode-overlay-texture-light to-escode-overlay-texture-dark rounded-2x">
                    <div className="z-100 h-full w-full error-panel flex items-center justify-center flex-col gap-8">
                        <h1 className="text-white text-4xl font-medium tracking-wider">
                            {t('faq.page-title')}
                        </h1>
                        {/* <SearchBar
                            placeholder={t('faq.search-placeholder')}
                            className="w-full"
                        /> Feature to be fleshed out and integrated in the future */}
                    </div>
                </div>
            </div>
            <div className="px-52">
                <div>
                    <div className="mb-12">
                        <div className="flex justify-center gap-3">
                            <a
                                href="/assets/downloads/blank_deposit_form.doc"
                                download
                            >
                                <Button variant="tertiary">
                                    <FontAwesomeIcon icon={faArrowDownToLine} />
                                    {t('faq.download.deposit-form')}
                                </Button>
                            </a>
                            <a
                                href="/assets/downloads/view_portal_vendor_handbook.pdf"
                                download
                            >
                                <Button variant="tertiary">
                                    <FontAwesomeIcon icon={faArrowDownToLine} />
                                    {t('faq.download.user-guide')}
                                </Button>
                            </a>
                            <a href="/assets/PGP/PGPKey.zip" download>
                                <Button variant="tertiary">
                                    <FontAwesomeIcon icon={faArrowDownToLine} />
                                    {t('faq.download.pgp')}
                                </Button>
                            </a>
                            <a
                                href="/assets/downloads/view_security_doc_en.pdf"
                                download
                            >
                                <Button variant="tertiary">
                                    <FontAwesomeIcon icon={faArrowDownToLine} />
                                    {t('faq.download.security-document')}
                                </Button>
                            </a>
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <h2 className="text-3xl font-medium">
                            {t('faq.content-heading')}
                        </h2>
                    </div>
                    <div className="mt-12">
                        <div className="flex justify-between gap-24">
                            <div className="flex flex-col items-start w-1/2">
                                <SectionHeading
                                    headingTextClasses="text-2xl font-medium"
                                    heading={t('faq.heading.general-questions')}
                                />
                                <CollapsiblePanel items={generalQuestions} />
                            </div>
                            <div className="flex flex-col items-start w-1/2">
                                <SectionHeading
                                    headingTextClasses="text-2xl font-medium"
                                    heading={t('faq.heading.deposit-questions')}
                                />
                                <CollapsiblePanel items={depositQuestions} />
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center gap-24">
                <div className="flex flex-col items-center gap-9">
                    <SectionHeading
                        className="mb-0"
                        headingTextClasses="text-2xl font-medium"
                        heading={t('faq.heading.verification')}
                    />
                    <p>{t('faq.heading.verification-cta')}</p>
                    <div className="flex items-center gap-2">
                        <a
                            href="https://www.escode.com/escrow-verification/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button variant="tertiary" onClick={() => null}>
                                {t('faq.verif-button.software')} Escrow{' '}
                                {t('faq.verif-button.verification')}
                                <FontAwesomeIcon
                                    icon={faArrowUpRightFromSquare}
                                />
                            </Button>
                        </a>
                        <a
                            href="https://www.escode.com/saas-escrow/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button variant="tertiary" onClick={() => null}>
                                SaaS Escrow
                                <FontAwesomeIcon
                                    icon={faArrowUpRightFromSquare}
                                />
                            </Button>
                        </a>
                    </div>
                </div>
                <div className="flex flex-col items-center gap-9">
                    <SectionHeading
                        className="mb-0"
                        headingTextClasses="text-2xl font-medium"
                        heading={t('faq.heading.questions')}
                    />
                    <p>{t('faq.heading.questions-cta')}</p>
                    <Button onClick={handleContactModal} variant="tertiary">
                        <FontAwesomeIcon icon={faEnvelope} />
                        {t('faq.contact-us')}
                    </Button>
                </div>
                <div className="flex flex-col items-center">
                    &copy; NCC Group {currentYear}. All rights reserved.
                </div>
            </div>
        </div>
    );
};

export default Help;
