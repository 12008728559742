import { Button, SkeletonLine } from 'lib-ui';
import { ForwardedRef, forwardRef, useCallback } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import toastFunctions from 'lib-ui/toast/Toast';
import useCreateDeposit from 'core/api/client-portal-v2/hooks/deposits/use-create-deposit';

interface HighlightAgreementGridItemProps {
    agreementId?: number | undefined;
    agreementNumber?: number | undefined;
    loading?: boolean;
    viewingPartyCanCreateDeposit?: boolean;
}

function HighlightAgreementGridItem(
    {
        agreementId = 0,
        agreementNumber,
        loading,
        viewingPartyCanCreateDeposit
    }: HighlightAgreementGridItemProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const goTo = useNavigate();
    const { pop } = useModals();

    const { isLoading: creating, mutate: createDeposit } = useCreateDeposit();

    const submit = useCallback(() => {
        queryClient.invalidateQueries(['use-deposit-details-v2']);
        createDeposit(
            { relatedAgreements: { relatedAgreements: [agreementId] } },
            {
                onError() {
                    toastFunctions.error(t('toast.error'));
                },
                onSuccess(res) {
                    toastFunctions.success(t('toast.success'));
                    localStorage.setItem('depositId', res.data.depositId);
                    pop();
                    goTo(`/deposit-now/${res.data.depositId}/contact-details`);
                }
            }
        );
    }, [agreementId, createDeposit, goTo, pop, queryClient, t]);

    const cssClasses = classNames(
        'relative flex justify-between items-center gap-6 pb-3.5',
        { 'border-b border-brand-escode-neonblue-neonblue-10': !loading }
    );

    return (
        <div ref={ref} className={cssClasses} style={{ direction: 'initial' }}>
            {!!loading && <SkeletonLine absolute />}
            <div className="text-xl text-general-grey-grey-90 font-medium">
                {agreementNumber}
            </div>
            <Button
                disabled={!viewingPartyCanCreateDeposit || creating}
                onClick={submit}
                size="s"
                variant="tertiary"
            >
                {t('highlight-agreement.deposit')}
            </Button>
        </div>
    );
}

export default forwardRef(HighlightAgreementGridItem);
