import { ICellRendererParams, Typography, ValueGetterParams } from 'lib-ui';
import { memo } from 'react';

import {
    AgreementListResponseViewModel,
    AgreementPartyViewModel,
    AgreementViewModel
} from 'core/api/client-portal/autogenerated/data-contracts';
import { AgreementsTableContext } from './agreements-table-context';
import { CellClickedEvent } from 'ag-grid-community';
import LicenseesDetailsModal from '../licensee/licensees-details-modal';

function getAgreementLicenseesString({
    context: { t },
    data
}: Pick<
    ValueGetterParams<
        AgreementPartyViewModel[] | null | undefined,
        AgreementsTableContext
    >,
    'context' | 'data'
>) {
    if (!data) return;

    const numOfLicensees = data.length;
    if (numOfLicensees > 1) {
        return t('agreement.multi-licensee');
    } else if (numOfLicensees === 1) {
        return data[0].registeredName ?? undefined;
    } else {
        return t('agreement.no-licensee');
    }
}

function agreementLicenseesValueGetter({
    context,
    data
}: ValueGetterParams<AgreementViewModel, AgreementsTableContext>):
    | string
    | undefined {
    return getAgreementLicenseesString({ context, data: data.licensees });
}

const agreementLicenseesOnCellClick = ({
    context: { goTo, push },
    data
}: CellClickedEvent<AgreementViewModel>) => {
    if (data?.licensees && data.licensees.length > 1)
        push(LicenseesDetailsModal, { data: data.licensees });
    else if (data) {
        goTo(`/agreements/${data.agreementId}`);
    }
};

function AgreementLicenseesCellRender({
    data,
    value
}: ICellRendererParams<AgreementViewModel, AgreementsTableContext>) {
    if (data?.licensees === null || data?.licensees === undefined) return null;

    const numOfLicensees = data.licensees.length;

    return numOfLicensees > 1 ? (
        <Typography.Link>{value}</Typography.Link>
    ) : (
        <>{value}</>
    );
}

function getLicenseesOptions(data: AgreementListResponseViewModel) {
    const options = data
        .agreements!.flatMap((agreement) => agreement.licensees)
        .map((licensee) => ({
            key:
                (licensee?.agreementPartyId as unknown as string) ??
                Math.floor(Math.random() * 1000).toString(),
            label: licensee?.registeredName ?? ''
        }));

    return options;
}

export default memo(AgreementLicenseesCellRender);
export {
    agreementLicenseesOnCellClick,
    agreementLicenseesValueGetter,
    getAgreementLicenseesString,
    getLicenseesOptions
};
