import { DocumentMetadataResponseViewModel } from '../../autogenerated/data-contracts';
import { UseQueryOptions, useQuery } from 'react-query';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';


type DepositMediaCheckDownloadParams = {
    agreementId: number | undefined;
    depositId: number | undefined;
    enabled?: boolean;
};

function useDepositVerificationList<TError = unknown>(
    query: DepositMediaCheckDownloadParams,
    options?: Omit<
        UseQueryOptions<
            DocumentMetadataResponseViewModel | undefined,
            ReactQueryError<TError>
        >,
        'queryKey' | 'queryFn'
    >
) {
    const auth = useAuth();

    return useQuery<
        DocumentMetadataResponseViewModel | undefined,
        ReactQueryError<TError>
    >(
        [
            'deposits',
            'documents',
            {
                query,
                token: auth.user?.access_token,
                tokenType: auth.user?.token_type
            }
        ],
        async () => {
            if (!query.agreementId || !query.depositId) {
                console.warn(
                    'Undefined agreementId or depositId in useDepositVerificationList'
                );
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const response =
                await controller.getTheMultipleVerificationReportsForADepositLinkedToAnAgreement(
                    query.agreementId,
                    query.depositId
                );
            return response.data;
        },
        options
    );
}

export default useDepositVerificationList;
export type { DepositMediaCheckDownloadParams };
